
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProductVersionsTable',
})
export default class ProductVersionsTable extends Vue {
    @Prop({ required: true }) private config!: Record<string, any>;
    @Prop({ required: false }) private filteredData!: Record<string, any>;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get columns() {
        return this.config.columns;
    }

    private get filteredTableData() {
        if (this.filteredData.length) {
            return this.filteredData;
        }

        return null;
    }

    private get tableData() {
        return this.config.tableData(); // this is a function that returns the data
    }

    private customRow(record: any) {
        return {
            on: {
                click: () => this.handleRowClick(record),
            },
        };
    }

    private handleRowClick(row: any) {
        this.$emit('rowClick', row);
    }

    private async mounted() {
        this.loadingOverlay.start();

        try {
            await this.config.entityFetchEndpoint();
        } catch {
            this.$notification.error({
                message: this.$t('Error') as string,
                description: this.$t('An error occurred while fetching orders') as string,
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }
}
